import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import {RasaLogo} from 'components/icons/rasalogo'
import {Input} from 'components/input/component'
import {preventDefaultThen} from 'components/link/component'
import { Loading } from 'components/loading'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as GlobalConstants from '../../constants'
import * as Actions from './actions'
import * as Constants from './constants'
import './styles.css'

const {Button, Col, Form, Row} = ReactStrap

interface TwoFactorAuthState {
  email: string,
  isLoading: boolean,
  serverError: boolean,
  resendSuccess: boolean,
}

interface MapDispatchToProps {
  submit2fa: any,
  resend2fa: any,
  setIsLoading: any,
  setServerError: any,
}

type Props = MapDispatchToProps & TwoFactorAuthState

interface State {
  email: string,
  confirmationCode: string,
  confirmationCodeExpiration: DropdownOption,
}

class TwoFactorAuthComponentClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props, '');
    this.state = {
      email: '',
      confirmationCode: '',
      confirmationCodeExpiration: Constants.AllExpirationOptions[0],
    }
  }

  public render() {
    return (
      <div className="two-factor-auth">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="two-factor-auth-wrapper">
          <Col md="6" lg="12" className="content">
            <h1>Two Factor Authentication</h1>
            <p className="enter-paragraph">Enter the 6 digit code you received by text message.</p>
            <Form onSubmit={preventDefaultThen(() => {
              this.props.setServerError(false)
              this.props.setIsLoading(true)
              this.props.submit2fa(this.props.email, this.state.confirmationCode,
                this.state.confirmationCodeExpiration.value)
              })}>
              <ReactStrap.FormGroup className="two-factor-code">
                <Input
                  className={'input-text'}
                  type="text"
                  name="confirmationCode"
                  onChange={(e) => {
                    this.updateField({name: 'confirmationCode', value: e.target.value.trim()})
                  }}
                  placeholder="Confirmation code"
                />
              </ReactStrap.FormGroup>
              <ReactStrap.FormGroup className="two-factor-expriation">
                <ReactStrap.Label className="input-label" for="confirmationCodeExpiration">
                  Remember for</ReactStrap.Label>
                <DropdownComponent data={Constants.AllExpirationOptions}
                              selected={this.state.confirmationCodeExpiration.key}
                              onChange={ (e: OnChangeEvent) =>
                                this.setState({
                                  confirmationCodeExpiration: e.selected,
                                })
                              }/>
              </ReactStrap.FormGroup>
              {this.props.isLoading && <Loading size="32"></Loading>}
              {this.props.resendSuccess && <div className="invalid-credentials">Code resent successfully!</div> }
              {this.props.serverError &&
              <div className="confirmation-code-error">
                <div className="invalid-credentials">Invalid or Expired Confirmation Code!</div>
              </div>}
              <Button className="btn-submit"
                disabled={!this.state.confirmationCode.trim().length || this.props.isLoading}
              >Submit</Button>
              <div>
                Didn't recieve the code?
                <a className="resend-link" href="#" onClick={() => {
                  this.setState({confirmationCode: ''})
                  this.props.resend2fa(this.props.email)
                }}>
                  Resend code</a> or contact our&nbsp;
                <a href={`${GlobalConstants.SUPPORT_MAIL_TO}?subject=Two-factor-authentication`}>
                support team</a>.
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }

  private updateField(event: any) {
    this.setState({
      ...this.state,
      [event.name]: event.value,
    })
  }
}

export const TwoFactorAuthComponent = connect(
  ({twoFactorAuth}: any) => ({
    email: twoFactorAuth.email,
    isLoading: twoFactorAuth.isLoading,
    serverError: twoFactorAuth.serverError,
    resendSuccess: twoFactorAuth.resendSuccess,
  }),
  {
    submit2fa: Actions.submit2fa,
    resend2fa: Actions.resend2fa,
    setIsLoading: Actions.setIsLoading,
    setServerError: Actions.setServerError,
  },
)(TwoFactorAuthComponentClass)
