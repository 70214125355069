import { RasaContext } from 'context'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { writeLog } from 'utils'
import { NOT_APPLICABLE, UNAUTHENTICATED_ROUTES } from './constants'
import './styles.css'
import { IGNORE_SENTRY_ERROR, SUPPORT_EMAIL, SUPPORT_MAIL_TO } from '../../constants'

const SEPERATOR: string = '%0D%0A'
interface ErrorProps {
  push: any,
}

interface ErrorState {
  community: string,
  errorMessage: string,
  hasError: boolean,
  user: string,
}
export class ErrorHandlerComponent extends Component<ErrorProps, ErrorState> {
  public static contextType = RasaContext
  public static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  private sharedStore: SharedStore
  constructor(props) {
    super(props)
    this.state = {
      community: '',
      errorMessage: '',
      hasError: false,
      user: '',
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.config),
    ]).then(([activeCommunity, person, config]) => {
      if (!activeCommunity || !person) {
        // we do not have any information about the user.
        // It may also be an unauthorized route.
        // Do not show the generic message in this case.
        if (!UNAUTHENTICATED_ROUTES.includes(window.location.pathname)) {
          // show error otherwise
          this.setState({ hasError: true })
          return
        }
      }

      this.setState({
        community: activeCommunity ? activeCommunity.communityId : NOT_APPLICABLE,
        user: person ? person.email : NOT_APPLICABLE,
      }, () => {
        this.logError()
      })
    }).catch((error: Error) => {
      if (IGNORE_SENTRY_ERROR.includes(String(error))) {
        return null
      }
      if (!(error instanceof Error)) {
        error = new Error(error);
      }
      throw error
    })
  }

  public componentDidCatch(error, errorInfo) {
    if (!this.state.errorMessage) {
      this.setState({
        errorMessage: `Error: ${error}, Stack: ${errorInfo.componentStack}`,
      }, () => {
        if (this.state.community) {
          this.logError()
        }
      })
    }
  }
  public render() {
    if (this.state.hasError) {
      return <div className="error-msg">
        <div >Oops! Try refreshing your page or click <a href="/">here</a> to navigate back to homepage.</div>
        <div>
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
          Please contact <a href={`${SUPPORT_MAIL_TO}?subject=Unknown Error - ${new Date()}&body=${this.getErrorMessage()}`}>{SUPPORT_EMAIL}</a> if you continue to have issues.
        </div>
      </div>
    } else {
      return this.props.children
    }
  }

  private logError = () => {
    if (this.state.errorMessage) {
      const payload = {
        community: this.state.community,
        user: this.state.user,
        error: this.state.errorMessage,
      }
      writeLog(payload)
    }
  }

  private getErrorMessage = () => {
    return `Dashboard UI Error report.${SEPERATOR}Community: ${this.state.community}${SEPERATOR}User: ${this.state.user}${SEPERATOR}Error: ${this.state.errorMessage}`
  }
}

export const ErrorHandler = connect(
  null,
  {
    push: Router.push,
  },
)(ErrorHandlerComponent)
