import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { GenericNavigatorSelectedItemProps } from 'components/generic-navigator/component'
import { DesktopNavigator } from 'components/generic-navigator/desktop'
import { SettingsIcon } from 'components/icons/settings'
import { RasaContext } from 'context'
import React, { Component } from 'react'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Constants from './constants'
import { PanelBarOption } from '../../components/panel-bar/types'
import * as Flash from '../../components/flash'
import { getValueWithRetry } from '../../generic/utility'

const settingsIcon = <SettingsIcon svgwidth={57.6} svgheight={57.6}/>

interface SettingsComponentState {
  isUserAdmin: boolean,
  isLoading: boolean,
  retryCount: number,
  sections: PanelBarOption[],
}

export class SettingsComponent extends Component<GenericNavigatorSelectedItemProps, SettingsComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: GenericNavigatorSelectedItemProps) {
    super(props)
    this.state = {
      isUserAdmin: false,
      isLoading: true,
      sections: [],
      retryCount: 1,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    getValueWithRetry(this.sharedStore, SharedKeys.activeCommunity).then((activeCommunity) => {
      const currentPlan = activeCommunity.billingInfo.currentPlan
      const isSuperAdmin = activeCommunity.role === Roles.super_admin
      const isPartnerAdmin = activeCommunity.role === Roles.partner_admin
      const isUserAdmin =   (activeCommunity.data.user_role === Roles.community_admin && activeCommunity.role === Roles.community_admin)
      const sections = Constants.sections(isSuperAdmin, isPartnerAdmin, isUserAdmin, currentPlan)
      this.setState({
        isUserAdmin,
        isLoading: false,
        sections,
      })
    }).catch((error) => {
      this.setState({
        isLoading: false,
      }, () => {
        this.context.store.dispatch(Flash.showFlashError(error))
      })
    })
  }

  public render() {
    return (
      this.state.isLoading ? null : <div>
        <DashboardMenu selected={DashboardMenuOption.settings} />
        <div>
          <DesktopNavigator
            selectedItem={this.props.selectedItem}
            sections={this.state.sections}
            baseRoute={DashboardMenuOption.settings}
            title="Settings"
            subTitle=""
            headerIcon= {settingsIcon}
          >
          </DesktopNavigator>
        </div>
      </div>
    );
  }
}
