import {ABTestsComponent} from 'components/analytics/ab_tests'
// import {AiKnowledgeComponent} from 'components/analytics/ai-knowledge'
import {ArticlesComponent} from 'components/analytics/articles'
import {DailyStatsComponent} from 'components/analytics/daily-stats'
import {ImagesClicksComponent} from 'components/analytics/images'
import {NewClicksComponent} from 'components/analytics/new-clicks'
import {OpensClicksComponent} from 'components/analytics/opens-clicks'
import {S3ReportsComponent} from 'components/analytics/s3-reports'
import {SendSummariesComponent} from 'components/analytics/send-summaries'
import {SourcesComponent} from 'components/analytics/sources'
import { SourcesRunReportComponent } from 'components/analytics/sources-report'
import { SourcesReportComponent } from 'components/analytics/sourcesReport'
import {TopicsComponent} from 'components/analytics/topics'
import {PanelBarOption} from 'components/panel-bar/types'
import React from 'react'

// import {AiKnowledge} from 'components/icons/aiknowledge'
import { AiKnowledgeComponent } from '../../components/analytics/ai-knowledge'
import { SegmentationComponent } from '../../components/analytics/segmentation'
import { AiKnowledgeTierTopicComponent } from '../../components/analytics/ai-knowledge-topic'
import { EmailHealthComponent } from '../../components/analytics/email_health'
import { PerformanceInsight } from '../../components/icons/performanceInsights'
import { AiReportIcons } from '../../components/icons/aiReports'
import { ContentEngagements } from '../../components/icons/contentEngagement'
import { EnterpriseReport } from '../../components/icons/enterpriseReports'

const PerformanceInsightsIcon = <PerformanceInsight svgwidth={29.8} svgheight={29.8} />
const AIReportsIcon = <AiReportIcons svgwidth={29.8} svgheight={29.8} />
const ContentEngagementIcon = <ContentEngagements svgwidth={29.8} svgheight={41.1}/>
const EnterpriseReportsIcon = <EnterpriseReport svgwidth={29.8} svgheight={41.1}/>

const PerformanceInsights = 'Performance Insights'
const AIReports = 'AI Reports'
const ContentEngagement =  'Content Engagement'
const EnterpriseReports =  'Enterprise Reports'

export const RETRY_LIMIT = 2
export const RETRY_WAIT_TIME = 5000
export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active community doesn\'t found'
export const allSections: PanelBarOption[] = [
  {
    category: PerformanceInsights,
    component: OpensClicksComponent,
    icon: PerformanceInsightsIcon,
    isMenu: true,
    name: 'Opens/Clicks',
    route: '',
  },
  {
    category: PerformanceInsights,
    component: SendSummariesComponent,
    icon: PerformanceInsightsIcon,
    isMenu: true,
    name: 'Send Summaries',
    route: 'send-summaries',
  },
  {
    category: PerformanceInsights,
    component: DailyStatsComponent,
    icon: PerformanceInsightsIcon,
    isMenu: true,
    name: 'Daily Stats',
    route: 'dailystats',
  },
  {
    category: PerformanceInsights,
    component: SegmentationComponent,
    icon: PerformanceInsightsIcon,
    isMenu: true,
    name: 'Segmentation Report',
    route: 'segmentation',
  },
  {
    category: PerformanceInsights,
    component: EmailHealthComponent,
    icon: PerformanceInsightsIcon,
    isMenu: true,
    name: 'Email Health Report',
    route: 'email-health-report',
  },
  {
    category: AIReports,
    component: AiKnowledgeComponent,
    icon: AIReportsIcon,
    isMenu: true,
    name: 'AI Knowledge Tiers',
    route: 'ai-knowledge-tier',
  },
  {
    category: AIReports,
    component: AiKnowledgeTierTopicComponent,
    icon: AIReportsIcon,
    isMenu: true,
    name: 'AI Knowledge Topics',
    route: 'ai-knowledge-topic',
  },
  {
    category: ContentEngagement,
    component: TopicsComponent,
    icon: ContentEngagementIcon,
    isMenu: true,
    name: 'Topics',
    route: 'topics',
  },
  {
    category: ContentEngagement,
    component: ArticlesComponent,
    icon: ContentEngagementIcon,
    isMenu: true,
    name: 'Articles',
    route: 'articles',
  },
  {
    category: ContentEngagement,
    component: SourcesComponent,
    icon: ContentEngagementIcon,
    isMenu: true,
    name: 'Sources',
    route: 'sources',
  },
  {
    category: ContentEngagement,
    component: ImagesClicksComponent,
    icon: ContentEngagementIcon,
    isMenu: true,
    name: 'Images',
    route: 'images-report',
  },
  {
    category: ContentEngagement,
    component: NewClicksComponent,
    icon: ContentEngagementIcon,
    isMenu: true,
    name: 'Other Clicks',
    route: 'new-clicks',
  },
  // {
  //   category: '',
  //   component: AiKnowledgeComponent,
  //   icon: aiKnowledge,
  //   name: 'AI Knowledge',
  //   route: 'ai-knowledge',
  // },
  {
    category: EnterpriseReports,
    component: S3ReportsComponent,
    icon: EnterpriseReportsIcon,
    isMenu: true,
    name: 'Link Reports',
    route: 'link-reports',
  },
  {
    category: EnterpriseReports,
    component: SourcesReportComponent,
    icon: EnterpriseReportsIcon,
    isMenu: true,
    name: 'Reports',
    route: 'reports',
  },
  {
    category: EnterpriseReports,
    component: SourcesRunReportComponent,
    icon: EnterpriseReportsIcon,
    isMenu: true,
    name: 'Source Reports',
    route: 'source-reports',
  },
  {
    category: EnterpriseReports,
    component: ABTestsComponent,
    icon: EnterpriseReportsIcon,
    isMenu: true,
    name: 'AB Tests',
    route: 'ab-tests',
  },
]

const SUPER_USER_REPORTS = [
  'Source Reports',
  'Recommendation Performance'
]

export const sections = (
  hasS3ReportAccess: boolean,
  hasReportsAccess: boolean,
  hasAbReportsAccess: boolean,
  isSuperUser: boolean,
) => {
  const availableSections = allSections.filter((item) => {
    if (item.name === 'Link Reports') {
      return hasS3ReportAccess
    } else if (item.name === 'Reports') {
      return hasReportsAccess
    } else if (item.name === 'AB Tests') {
      return hasAbReportsAccess
    } else if (SUPER_USER_REPORTS.includes(item.name)) {
      return isSuperUser
    } else {
      return true
    }
  })
  return availableSections
}
