import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import {RasaLogo} from 'components/icons/rasalogo'
import { RasaBrowserComponent } from 'generic/rasaBrowserComponent'
import React from 'react'
import * as ReactStrap from 'reactstrap'
import { ERROR_TYPE_QUERY, HandledPageErrorType } from './constants'
import * as GlobalConstants from '../../constants'
import './_styles.scss'

const {Col, Row} = ReactStrap

export class HandledErrorPageComponent extends RasaBrowserComponent<any, any> {
  constructor(props: any) {
    super(props, {})
  }

  public render() {
    return (
      <div className="handled-error-page">
        <div className="dashboard-menu">
          <div className={'dashboard-menu-item dashboard-rasa-logo'}
            onClick={() => this.handleLogoClick()}>
            <RasaLogo/>
          </div>
          <div className="dashboard-popup-menu-item clickable-item need-a-help">
            <a href={GlobalConstants.RASA_HELP_BASE_URL} target="_blank" rel="noopener">Help & Support</a>
          </div>
        </div>
        <Row className="page-wrapper">
          <Col md="6" lg="12" className="content">
            <h1>{this.getHeader()}</h1>
            <p className="enter-paragraph">{this.getContent()}</p>
          </Col>
        </Row>
      </div>
    )
  }

  private getHeader() {
    const errorType = this.getQuery(ERROR_TYPE_QUERY) || HandledPageErrorType.UNKNOWN
    switch (errorType) {
      case HandledPageErrorType.NO_NEWSLETTER:
        return 'No Newsletter'
      default:
        return 'Unknown Error!'
    }
  }
  private getContent() {
    const errorType = this.getQuery(ERROR_TYPE_QUERY) || HandledPageErrorType.UNKNOWN
    switch (errorType) {
      case HandledPageErrorType.NO_NEWSLETTER:
        return <div>
          You do not have access to any newsletter. If you think this is an error, please contact our <br></br>
          <a href={`${GlobalConstants.SUPPORT_EMAIL}?subject=No Newsletter}`}>Customer Support.</a>
        </div>
      default:
        return <div>
          Oops, we couldn't recognise this error! Please contact our <br></br>
          <a href={`${GlobalConstants.SUPPORT_EMAIL}?subject=Unknown Error}`}>Customer Support.</a>
        </div>
    }
  }

  private handleLogoClick() {
    window.location.replace(DashboardMenuOption.dashboard)
  }
}
