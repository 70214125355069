import { ArticleActivity } from 'components/cs-dashboard/article-activity/component'
import { ContentHistory } from 'components/icons/contentHistory'
import { PanelBarOption } from 'components/panel-bar/types'

import * as React from 'react'
import { ScheduleHistory } from "../../components/cs-dashboard/schedule-history";
import { SourceCheck } from 'components/cs-dashboard/source-check'
import { UrlRequestHistoryComponent } from 'components/analytics/url-request-history'
import { UrlRequestContentBlockComponent } from '../../components/analytics/url-request-content-block'
import { BulkSourceImport } from '../../components/cs-dashboard/bulk-source-import'
import { SourceHistory } from '../../components/cs-dashboard/source-history'
 import { AccountFeature } from '../../components/cs-dashboard/account-feature'
import { AppNotification } from 'components/cs-dashboard/app-notification/component';
import {RecommendationsPerformanceComponent} from "../../components/analytics/recommendations";
import {AiReportIcons} from "../../components/icons/aiReports";
import { OutageNotification } from 'components/cs-dashboard/outage-notification';

const articleActivityIcon = <ContentHistory svgwidth={34.7} svgheight={32.9}/>
const AIReportsIcon = <AiReportIcons svgwidth={29.8} svgheight={29.8} />

export const allSections: PanelBarOption[] = [
  {
    category: '',
    component: ArticleActivity,
    icon: articleActivityIcon,
    name: 'Article Activity',
    route: 'article-activity',
  },
  {
    category: '',
    component: BulkSourceImport,
    icon: articleActivityIcon,
    name: 'Bulk Source Import',
    route: 'bulk-source-import',
  },
  {
    category: '',
    component: ScheduleHistory,
    icon: articleActivityIcon,
    name: 'Schedule History',
    route: 'schedule-history',
  },
  {
    category: '',
    component: SourceCheck,
    icon: articleActivityIcon,
    name: 'Check Source URL',
    route: 'source-check',
  },
  {
    category: '',
    component: SourceHistory,
    icon: articleActivityIcon,
    name: 'Check Source History',
    route: 'source-history',
  },
  {
    category: '',
    component: UrlRequestHistoryComponent,
    icon: articleActivityIcon,
    name: 'URL Request History',
    route: 'url-request-history',
  },
  {
    category: '',
    component: UrlRequestContentBlockComponent,
    icon: articleActivityIcon,
    name: 'URL Request Content Block',
    route: 'url-request-content-block',
  },
  {
    category: '',
    component: AccountFeature,
    icon: articleActivityIcon,
    name: 'Account Feature',
    route: 'account-feature',
  },
  {
    category: '',
    component: AppNotification,
    icon: articleActivityIcon,
    name: 'App Notifications',
    route: 'app-notifications',
  },
  {
    category: '',
    component: OutageNotification,
    icon: articleActivityIcon,
    name: 'Outage Notification',
    route: 'outage-notification',
  },
  {
    category: '',
    component: RecommendationsPerformanceComponent,
    icon: AIReportsIcon,
    name: 'Recommendation Performance',
    route: 'recommendation-performance',
  }
]

export const sections = (
  isSuperUser: boolean,
) => {
  return allSections.filter((item) => {
    if (item.name === 'Bulk Source Import') {
      return isSuperUser
    } else {
      return true
    }
  })
}

export const RETRY_LIMIT = 2
export const RETRY_WAIT_TIME = 5000

export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active community doesn\'t found'
