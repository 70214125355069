import { AjaxWrapper } from 'generic/ajaxWrapper'
import * as ReduxObservable from 'redux-observable'
import * as Request from 'request'
import * as Router from 'router'
import {filter, map, mergeMap, tap} from 'rxjs/operators'
import * as AuthConstants from '../../auth/constants'
import * as TwoFactorAuthConstants from '../2fa-auth/constants'
import * as Actions from './actions'
import * as Constants from './constants'

import * as RootUtils from '../../utils'

declare const RASA_ADMIN_UI_URL: string
declare const RASA_CAMPAIGNS_URL: string

const REDIRECT_URLS = {
  'campaigns': RASA_CAMPAIGNS_URL
}

export const onEnter: ReduxObservable.Epic = (action$, state$, {history}) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      filter(() => history.location.pathname === Constants.LOGIN_BASE_ROUTE),
      map(() => Actions.getSSOClients()),
    )

export const initializeSSOClients: ReduxObservable.Epic = (action$, state$, {}) =>
  action$.ofType(Request.successConstant(Constants.SSO_CLIENTS))
    .pipe(
      tap((action) => {
        window.localStorage.setItem('googleClientId', action.payload.response.googleClientId)
      }),
      mergeMap((action) => [
        Actions.setFacebookAppId(action.payload.response.facebookAppId),
        ({type: 'SSO_CLIENTS_LOADED'}),
      ]),
    )

export const loginSuccessRedirctToOldDashboard: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_LOGIN))
  .pipe(
    filter((loginResponse) => loginResponse.payload.response.type.startsWith('ADMIN_UI_LOGIN')),
    // eslint-disable-next-line no-console
    tap((loginResponse) => console.log('loginResponse', loginResponse, RASA_ADMIN_UI_URL)),
    map((loginResponse) => window.location.replace(
      `${RASA_ADMIN_UI_URL}/auth/login?access_token=${loginResponse.payload.response.token}`)),
  )

export const loginSuccessRedirectWithCookie: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_LOGIN))
  .pipe(
    map(() => new URLSearchParams(window.location.search)),
    map((params: URLSearchParams) => {
      const redirectUrl = REDIRECT_URLS[params.get('redirect')]
      if (redirectUrl) {
        const port = params.get('port')
        if ( port ) {
          return `http://localhost:${port}`
        } else {
          return redirectUrl
        }
      }
      return ''
    }),
    filter((redirect: string) => redirect.length > 0 ),
    map((redirectUrl: string) => RootUtils.redirectTo(redirectUrl, [])),
  )

export const loginSuccess2faEnabled: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_LOGIN))
  .pipe(
    filter((loginResponse) => loginResponse.payload.response.is2faRequired),
    map((loginResponse) => {
      return window.location.replace(`${TwoFactorAuthConstants.FA_BASE_ROUTE}?email=${loginResponse.payload.response.userEmail ?
        loginResponse.payload.response.userEmail : JSON.parse(loginResponse.payload.request.body).email}`)
    }),
  )

export const hubspotUiLoginSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_LOGIN))
  .pipe(
    filter(() => window.localStorage.getItem(Constants.HUBSPOT_REDIRECT_TO)),
    filter((loginResponse) => !loginResponse.payload.response.is2faRequired),
    map((loginResponse) => {
      const redirectTo: string = window.localStorage.getItem(Constants.HUBSPOT_REDIRECT_TO)
      window.localStorage.removeItem(Constants.HUBSPOT_REDIRECT_TO)
      return Router.redirect(`${redirectTo}?t=${loginResponse.payload.response.token}`)
    }),
  )

export const hubspotValidTokenSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(AuthConstants.VALID_TOKEN)
  .pipe(
    filter(() => window.localStorage.getItem(Constants.HUBSPOT_REDIRECT_TO)),
    map((loginResponse) => {
      const redirectTo: string = window.localStorage.getItem(Constants.HUBSPOT_REDIRECT_TO)
      window.localStorage.removeItem(Constants.HUBSPOT_REDIRECT_TO)
      return Router.redirect(`${redirectTo}?t=${window.localStorage.getItem(AuthConstants.RASA_AUTH_TOKEN)}`)
    }),
  )

export const loginSuccess: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.successConstant(Constants.SUBMIT_LOGIN))
  .pipe(
    filter(() => !window.localStorage.getItem(Constants.HUBSPOT_REDIRECT_TO)),
    filter((loginResponse) => !loginResponse.payload.response.type.startsWith('ADMIN_UI_LOGIN')),
    filter((loginResponse) => !loginResponse.payload.response.is2faRequired),
    tap((loginResponse) => window.localStorage.setItem(AuthConstants.RASA_AUTH_TOKEN,
      loginResponse.payload.response.token)),
    tap((loginResponse) => AjaxWrapper.setToken(loginResponse.payload.response.token)),
    map(() => Actions.loginInProgress(false)),
    map(() => window.localStorage.getItem(Constants.REDIRECT_TO)),
    tap(() => window.localStorage.removeItem(Constants.REDIRECT_TO)),
    map((redirctTo) => Router.redirect(redirctTo || '/')),
  )

export const loginFailed: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.errorConstant(Constants.SUBMIT_LOGIN))
    .pipe(
      mergeMap((response) => [
        Actions.loginInProgress(false),
        Actions.loginFailed(true, response),
      ]),
    )

export const loginExpired: ReduxObservable.Epic = (action$, state$, {window}) =>
  action$.ofType(Request.errorConstant(Constants.SUBMIT_LOGIN))
    .pipe(
      filter((loginResponse) => loginResponse.payload.error
        && loginResponse.payload.error.response
        && loginResponse.payload.error.response.message
        && loginResponse.payload.error.response.message.startsWith('Expired')),
      map(() => window.location.href = Constants.EXPIRE_REDIRECT_URL),
    )

export const epic = ReduxObservable.combineEpics(onEnter,
  initializeSSOClients,
  hubspotUiLoginSuccess,
  hubspotValidTokenSuccess,
  loginSuccess,
  loginFailed,
  loginExpired,
  loginSuccessRedirctToOldDashboard,
  loginSuccess2faEnabled)
