import { DashboardMenu, DashboardMenuOption } from 'components/dashboard-menu/component'
import { EmailTemplatePreview } from 'components/email-layout/preview'
import React, { Component } from 'react'

export class PreviewComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.preview} />
        <div>
            <EmailTemplatePreview />
        </div>
      </div>
    )
  }
}
