import React from 'react'
import * as LoginConstants from '../login/constants'
import * as LogoutConstants from '../logout/constants'
import * as SignupConstants from '../signup/constants'
import * as ForgotPasswordConstants from '../forgot-password/constants'
import * as HandledErrorConstants from '../handled-error/constants'
import * as ResetPasswordConstants from '../reset-password/constants'
import * as FAConstants from '../2fa-auth/constants'
import { SUPPORT_MAIL_TO } from '../../constants'

export const UNAUTHENTICATED_ROUTES = [
  LoginConstants.LOGIN_BASE_ROUTE,
  LogoutConstants.LOGOUT_BASE_ROUTE,
  SignupConstants.SIGNUP_BASE_ROUTE,
  ForgotPasswordConstants.FORGOT_PASSWORD_BASE_ROUTE,
  ForgotPasswordConstants.FORGOT_PASSWORD_SUCCESS_ROUTE,
  HandledErrorConstants.HANDLED_ERROR_BASE_ROUTE,
  ResetPasswordConstants.RESET_PASSWORD_BASE_ROUTE,
  FAConstants.FA_BASE_ROUTE,
]

export const GENERIC_ERROR_ROUTE = '/error'
export const GENERIC_ERROR_HEADING = 'Unknown Error!'
export const genericErrorContent = () => {
 return <div>
  Oops, we couldn't recognise this error! Please contact our <br></br>
  {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
  <a href={`${SUPPORT_MAIL_TO}?subject=Unknown Error - ${new Date()}`}>Customer Support.</a>
</div>
}

export const NOT_APPLICABLE = 'NA'
